import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { compose } from 'recompose'

import { AuthUserContext } from '../Session'

import { SignUpLink } from '../SignUp'
import { FacebookIcon, GoogleIcon } from '../Icons'
import { PasswordForgetLink } from '../PasswordForget'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const policyTextStyle = {
  width: '100%',
  maxWidth: '273px',
  marginBottom: '16px',
  fontSize: '12px',
  display: 'block',
  margin: 'auto',
  paddingBottom: '40px'
}

const linkStyle = {
  color: '#FA6400'
}

const SignInPage = () => {
  let navigate = useNavigate()

  return (
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? (
          navigate(ROUTES.ACCOUNT)
        ) : (
          <div className='centerContent'>
            <div className='sectionContent'>
              <h1 className='sectionTitle'>Welcome back</h1>
              <p>Make more then a step and get started.</p>
              <SignInForm route={ROUTES.ACCOUNT} />
              <SignInFacebook
                route={ROUTES.ACCOUNT}
                title='Log in with Facebook'
              />
              <SignInGoogle
                route={ROUTES.ACCOUNT}
                title='Log in with Google'
              />
              {/* <SignInTwitter /> */}
              <SignUpLink />
              <p style={policyTextStyle}>
              By continuing, you agree to our{' '}
                <a href='#terms' style={linkStyle}>
                Terms of Use
                </a>{' '}
                and our{' '}
                <a href='#privacy-policy' style={linkStyle}>
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        )
      }
    </AuthUserContext.Consumer>
  )
}

const INITIAL_STATE = {
  email: '',
  password: ''
}

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential'
const ERROR_MSG_ACCOUNT_EXISTS = `An account with this email already exists, try logging into your account instead.`

const SignInFormBase = props => {
  const [userInfo, setUserInfo] = useState(INITIAL_STATE)
  const [error, setError] = useState(null)
  let navigate = useNavigate()

  const onSubmit = event => {
    console.log(userInfo)
    const { email, password } = userInfo
    console.log(email, password)

    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setUserInfo(INITIAL_STATE)
        navigate(props.route)
      })
      .catch(error => {
        console.log('error')
        setError(error)
      })
    event.preventDefault()
  }

  const onChange = event => {
    const { name, value } = event.target
    setUserInfo(userInfo => ({ ...userInfo, [name]: value }))
  }

  const isInvalid = userInfo.password === '' || userInfo.email === ''
  return (
    <form onSubmit={onSubmit}>
      <input
        name='email'
        value={userInfo.email}
        onChange={onChange}
        type='text'
        placeholder='E-post'
        className='inputStyle'
      />
      <input
        name='password'
        value={userInfo.password}
        onChange={onChange}
        type='password'
        placeholder='Password'
        className='inputStyle'
      />
      <input type='submit' style={{ display: 'none' }} />
      {error && <p className='errorMessage'>{error.message}</p>}
      {error && <PasswordForgetLink email={userInfo.email} />}
      <input type='submit' style={{ display: 'none' }} />
      <button disabled={isInvalid} type='submit' className='buttonStyle'>
        Log in
      </button>
    </form>
  )
}

const SignInFacebookBase = props => {
  const [error, setError] = useState(null)
  let navigate = useNavigate()

  const onSubmit = event => {
    props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        navigate(props.route)
        // Create a user in your Firebase Realtime Database too
        return props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email
        })
      })
      .then(() => {
        setError(null)
        //Check if subscription
        navigate(props.route)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }
        setError(error)
      })

    event.preventDefault()
  }

  return (
    <form onSubmit={onSubmit}>
      <button type='submit' className='socialButton'>
        <FacebookIcon style={{ paddingRight: '8px' }} />
        {props.title}
      </button>
      {error && <p className='errorMessage'>{error.message}</p>}
    </form>
  )
}

const SignInGoogleBase = props => {
  const [error, setError] = useState(null)
  let navigate = useNavigate()

  const onSubmit = event => {
    props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // console.log('WHAT1');
        navigate(props.route)
        // Create a user in your Firebase Realtime Database too
        return props.firebase.user(socialAuthUser.user.uid).set({
          // username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email
        })
      })
      .then(() => {
        setError(null)
        navigate(props.route)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }
        setError(error)
      })

    event.preventDefault()
  }

  return (
    <form onSubmit={onSubmit}>
      <button type='submit' className='socialButton'>
        <GoogleIcon style={{ paddingRight: '8px' }} />
        {props.title}
      </button>
      {error && <p className='errorMessage'>{error.message}</p>}
    </form>
  )
}

const withRouter = Component => props => {
  const navigate = useNavigate()
  return <Component {...props} navigate={navigate} />
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase)

const SignInFacebook = compose(withRouter, withFirebase)(SignInFacebookBase)

const SignInGoogle = compose(withRouter, withFirebase)(SignInGoogleBase)

export default SignInPage

export { SignInForm, SignInGoogle, SignInFacebook }
