import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { AuthUserContext } from '../Session'

import * as ROUTES from '../../constants/routes'

const ChangeEmailPage = props => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  let navigate = useNavigate()

  const onSubmit = event => {
    // console.log(passwordOne);
    // navigate(ROUTES.ACCOUNT);
    props.firebase
      .doEmailUpdate(email)
      .then(() => {
        setEmail('')
        navigate(ROUTES.ACCOUNT)
        setError(null)
      })
      .catch(error => {
        setError(error)
      })

    event.preventDefault()
  }

  const onChange = event => {
    const { value } = event.target
    // console.log(name, value);
    setEmail(value)
  }

  const isInvalid = email === ''

  return (
    <div className='accountBox'>
      <div className='accountContent'>
        <h1>Byt e-post</h1>
        <p>För att ändra ditt e-post fyll i formuläret nedan</p>
        <form onSubmit={onSubmit}>
          <input
            name='email'
            value={email}
            onChange={onChange}
            type='email'
            placeholder='Ny e-post'
            className='inputStyle'
            style={{ marginLeft: 0 }}
            autoComplete='new-email'
          />
          <button disabled={isInvalid} type='submit' className='buttonStyle'>
            Spara
          </button>

          {error && (
            <p className='errorMessage' style={{ marginLeft: 0 }}>
              {error.message}
            </p>
          )}
        </form>
      </div>
    </div>
  )
}

export default withFirebase(ChangeEmailPage)
