import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';

const PayBase = (props) => {
  const user = props.authUser;
  const [userData, setUserData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (user) {
      const userRef = props.firebase.firedb.collection('users').doc(user?.uid);
      console.log('data', userRef);
      props.firebase.user(user?.uid, (error, data) => {
        if (error) {
          // Handle the error
        } else {
          console.log(data);
          setUserData(data); // Set the user data
          setDataLoaded(true); // Data has been loaded
        }
      });
    }
  }, []);

  const connectGoogleFit = () => {
    if (dataLoaded && userData.spikeUserId) {
      // Data exists, and it's been loaded, so do nothing
      return;
    }
    const newURL = `https://api.spikeapi.com/init-user-integration/?provider=google_fit&user_id=${user.uid}&client_id=e7408672-681b-4a29-a812-0613f3b61564`;
    window.location.replace(newURL);
  };

  return (
    <div className="plansPage">
      {dataLoaded && userData.spikeUserId ? (
        <p>Account already connected</p>
      ) : dataLoaded ? (
        <button
          type="submit"
          className="accountButton"
          onClick={connectGoogleFit}
        >
          Google Fit
        </button>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

const PlansPage = withFirebase(PayBase);

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(PlansPage);
