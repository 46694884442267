import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { AuthUserContext } from '../Session'

import * as ROUTES from '../../constants/routes'

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: ''
}

const ChangePasswordPage = props => {
  const [passwords, setPasswords] = useState(INITIAL_STATE)
  const [error, setError] = useState(null)
  let navigate = useNavigate()

  const onSubmit = event => {
    const { passwordOne } = passwords
    // console.log(passwordOne);
    // navigate(ROUTES.ACCOUNT);
    props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        setPasswords(INITIAL_STATE)
        navigate(ROUTES.ACCOUNT)
        setError(null)
      })
      .catch(error => {
        setError(error)
      })

    event.preventDefault()
  }

  const onChange = event => {
    const { name, value } = event.target
    // console.log(name, value);
    setPasswords({ ...passwords, [name]: value })
  }

  const { passwordOne, passwordTwo } = passwords

  const isInvalid = passwordOne !== passwordTwo || passwordOne === ''

  return (
    <div className='accountBox'>
      <div className='accountContent'>
        <h1>Byt lösenord</h1>
        <p>För att ändra ditt lösenord fyll i formuläret nedan</p>
        <form onSubmit={onSubmit}>
          <input
            name='passwordOne'
            value={passwordOne}
            onChange={onChange}
            type='password'
            placeholder='Nytt lösenord'
            className='inputStyle'
            style={{ marginLeft: 0 }}
            autoComplete='new-password'
          />
          <input
            name='passwordTwo'
            value={passwordTwo}
            onChange={onChange}
            type='password'
            placeholder='Bekräfta nytt lösenord'
            className='inputStyle'
            style={{ marginLeft: 0 }}
            autoComplete='new-password'
          />
          <button disabled={isInvalid} type='submit' className='buttonStyle'>
            Spara
          </button>

          {error && (
            <p className='errorMessage' style={{ marginLeft: 0 }}>
              {error.message}
            </p>
          )}
        </form>
      </div>
    </div>
  )
}

export default withFirebase(ChangePasswordPage)
