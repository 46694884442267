import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { compose } from 'recompose'

import { SignInFacebook } from '../SignIn'
import { SignInGoogle } from '../SignIn'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const policyTextStyle = {
  width: '100%',
  maxWidth: '273px',
  marginBottom: '16px',
  fontSize: '12px',
  display: 'block',
  margin: 'auto',
  paddingBottom: '40px'
}

const linkStyle = {
  color: '#FA6400'
}

const SignUpPage = () => (
  <div className='centerContent'>
    <div className='sectionContent'>
      <h1 className='sectionTitle'>Skapa konto</h1>
      <p>Take more then a step and get started</p>
      <SignUpForm route={ROUTES.PAYMENT} />
      <SignInFacebook route={ROUTES.PAYMENT} title='Continue with Facebook' />
      <SignInGoogle route={ROUTES.PAYMENT} title='Continue with Google' />
      <p>
      Do you already have an account{' '}
        <Link to={ROUTES.SIGN_IN} style={linkStyle}>
          Log in
        </Link>
      </p>
      <p style={policyTextStyle}>
      By continuing, you agree to our{' '}
        <a href='#terms' style={linkStyle}>
        Terms of Use
        </a>{' '}
        and our{' '}
        <a
          href='#privacy-policy'
          style={linkStyle}
        >
          Privacy Policy
        </a>
        .
      </p>
    </div>
  </div>
)

const INITIAL_STATE = {
  email: '',
  passwordOne: ''
  // passwordTwo: '',
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use'
const ERROR_MSG_ACCOUNT_EXISTS = `An account with this email already exists, try logging into your account instead.`

const SignUpFormBase = props => {
  const [userInfo, setUserInfo] = useState(INITIAL_STATE)
  const [error, setError] = useState(null)
  let navigate = useNavigate()

  const onSubmit = event => {
    const { email, passwordOne } = userInfo
    // console.log('HELLO');

    props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        // console.log('CREATED',authUser)
        navigate(props.route)
        props.firebase.user(authUser.user.uid).set({
          // username,
          email
        })
      })
      .then(() => {
        setUserInfo(INITIAL_STATE)
        setError(null)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        setError(error)
      })

    event.preventDefault()
  }

  const onChange = event => {
    const { name, value } = event.target
    setUserInfo({ ...userInfo, [name]: value })
  }

  // const onChangeCheckbox = (event) => {
  //   const { name, checked } = event.target;
  //   setUserInfo({...userInfo, [name]: checked})
  // };

  const {
    email,
    passwordOne,
    passwordTwo,
  } = userInfo

  const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === ''

  return (
    <form onSubmit={onSubmit}>
      {/* <input
        name='username'
        value={username}
        onChange={onChange}
        type='text'
        placeholder='Full Name'
        className='inputStyle'
      /> */}
      <input
        name='email'
        value={email}
        onChange={onChange}
        type='text'
        placeholder='E-mail'
        className='inputStyle'
      />
      <input
        name='passwordOne'
        value={passwordOne}
        onChange={onChange}
        type='password'
        placeholder='Password'
        className='inputStyle'
      />
      <input
        name='passwordTwo'
        value={passwordTwo}
        onChange={onChange}
        type='password'
        placeholder='Confirm password'
        className='inputStyle'
      />
      <input type='submit' style={{ display: 'none' }} />
      <button disabled={isInvalid} type='submit' className='buttonStyle'>
        Create account
      </button>

      {error && <p className='errorMessage'>{error.message}</p>}
    </form>
  )
}

const SignUpLink = () => (
  <p>
    Are you new here?{' '}
    <Link to={ROUTES.SIGN_UP} style={linkStyle}>
      Create account
    </Link>
  </p>
)

const withRouter = Component => props => {
  const navigate = useNavigate()
  return <Component {...props} navigate={navigate} />
}

const SignUpForm = compose(withRouter, withFirebase)(SignUpFormBase)

export default SignUpPage

export { SignUpForm, SignUpLink }
