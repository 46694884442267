import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDLJMeVcpJbBAtze_wpqT2PGyAFjF5CnO4",
  authDomain: "aleapapp.firebaseapp.com",
  projectId: "aleapapp",
  storageBucket: "aleapapp.appspot.com",
  messagingSenderId: "399443442004",
  appId: "1:399443442004:web:1b0e6ff9fc458c593430c1",
  measurementId: "G-L8ZXFB95VG"
};

class Firebase {
  constructor () {
    app.initializeApp(firebaseConfig)

    /* Helper */

    this.serverValue = app.database.ServerValue
    this.emailAuthProvider = app.auth.EmailAuthProvider

    /* Firebase APIs */

    this.app = app.app()
    this.auth = app.auth()
    // this.db = app.database();
    this.functions = app.functions()
    this.firedb = app.firestore()
    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider()
    this.facebookProvider = new app.auth.FacebookAuthProvider()
  }

  // *** Auth API ***

  doCratePortalLink = () =>
    this.functions.httpsCallable(
      'ext-firestore-stripe-subscriptions-createPortalLink'
    )

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider)

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider)

  doSignOut = () => {
    this.auth.signOut()
    window.location = '/'
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: 'https://accounts.blanchestories.com/account' //process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    })

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  doEmailUpdate = email => this.auth.currentUser.updateEmail(email)

  doGetUser = uid => this.firedb.collection('user').doc(uid)

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerData: authUser.providerData
        }

        next(authUser)
      } else {
        fallback()
      }
    })

  // *** User API ***

  //user = async uid => await this.firedb.collection('users').doc(uid).get();

  user = (uid, callback) => {
    this.firedb
      .collection('users')
      .doc(uid)
      .get()
      .then((documentSnapshot) => {
        const userData = documentSnapshot.data();
        callback(null, userData); // Call the callback with the data
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
        callback(error, null); // Call the callback with an error
      });
  };
  
  //user = uid => this.db.ref(`customers/${uid}`);
  //users = () => this.db.ref('customers');
}

export default Firebase
