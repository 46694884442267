import React from 'react'
import { compose } from 'recompose'
import { withAuthorization, AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'
import PlansPage from '../Payment/PlansPage'

const AccountPaymentBase = props => {
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className='accountBox'>
          <div className='accountContent'>
            <h1>Connect providers</h1>
            <PlansPage authUser={authUser} />
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const AccountPage = withFirebase(AccountPaymentBase)

const condition = authUser => !!authUser

export default compose(
  // withEmailVerification,
  withAuthorization(condition)
)(AccountPage)
