import React from 'react'
import { AppstoreIcon, GooglePlayIcon } from '../Icons'
import '../Icons/Icons.css'
import { compose } from 'recompose'

import { withAuthorization /*, withEmailVerification */ } from '../Session'

const LandingPage = () => (
  <div className='centerContent'>
    <div className='sectionContent'>
      <h1 className='sectionTitle'>Välkommen in!</h1>
      <p>Nu kan du börja ditt nya liv.</p>
      <p>Ladda ner appen</p>
      <a
        href='https://play.google.com/store/apps/details?id=com.blanchestories.blanchestories&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
        className='icon'
      >
        <GooglePlayIcon />
      </a>
      <a
        href='https://apps.apple.com/us/app/blanchestories/id1539883289?itsct=apps_box_badge&itscg=30200'
        className='icon'
      >
        <AppstoreIcon />
      </a>
    </div>
  </div>
)

const condition = authUser => !!authUser

export default compose(
  // withEmailVerification,
  withAuthorization(condition)
)(LandingPage)
