export const LANDING = '/landing'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/'
export const HOME = '/home'
export const PAYMENT = '/payment'
export const ACCOUNT = '/account'
export const CONNECT = '/connect'
export const CONNECTED = '/connected'
export const PASSWORD_CHANGE = '/change-password'
export const EMAIL_CHANGE = '/change-email'
export const PASSWORD_FORGET = '/password-forget'
