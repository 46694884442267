import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const PasswordForgetPage = () => (
  <div className='centerContent'>
    <div className='sectionContent'>
      <h1 className='sectionTitle'>Återställ lösenord</h1>
      <PasswordForgetForm />
    </div>
  </div>
)

const PasswordForgetFormBase = props => {
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(null)
  let navigate = useNavigate()

  const onSubmit = event => {
    console.log('going to send', email)

    props.firebase
      .doPasswordReset(email)
      .then(() => {
        console.log(email)
        setEmail(email)
        setError(null)
        setSent(true)
        console.log(sent)
      })
      .catch(error => {
        setError(error)
      })

    event.preventDefault()
  }

  const onChange = event => {
    const { value } = event.target
    console.log(value)
    setEmail(value)
  }

  //const isInvalid = email === '';

  const { search } = useLocation()

  useEffect(() => {
    const firstEmail = new URLSearchParams(search).get('email')
    if (!sent) {
      document.getElementById('emailInput').value = firstEmail
      setEmail(firstEmail)
    }
  }, [])

  const goBack = () => {
    navigate(ROUTES.SIGN_IN)
  }

  return (
    <>
      {sent ? (
        <div>
          <p>Ett mejl har skickats med anvisningar till <br />{email}</p>
          <button
            type='submit'
            className='buttonStyle'
            onClick={goBack}
          >
            Tillbaka
          </button>
        </div>
      ) : (
        <div>
          <p>
            Fyll i din e-post nedan och få ett mejl med anvisningar <br></br>för
            hur du återställer ditt lösenord.
          </p>
          <form onSubmit={onSubmit}>
            <input
              id='emailInput'
              name='email'
              value={email}
              onChange={onChange}
              type='text'
              placeholder='E-post'
              className='inputStyle'
            />
            <button type='submit' className='buttonStyle'>
              Skicka
            </button>

            {error && <p className='errorMessage'>{error.message}</p>}
          </form>
        </div>
      )}
    </>
  )
}

const linkStyle = {
  color: '#FA6400'
}

const PasswordForgetLink = props => (
  <p>
    Glömt lösenordet?{' '}
    <Link
      to={`${ROUTES.PASSWORD_FORGET}?email=${props.email}`}
      style={linkStyle}
    >
      Vi hjälper dig här{' '}
    </Link>
  </p>
)

export default PasswordForgetPage

const PasswordForgetForm = withFirebase(PasswordForgetFormBase)

export { PasswordForgetForm, PasswordForgetLink }
