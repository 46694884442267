import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { compose } from 'recompose'

import AuthUserContext from './context'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const withAuthorization = condition => Component => {
  const WithAuthorization = props => {
    let navigate = useNavigate()

    useEffect(() => {
      const unsubscribe = props.firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser)) {
            navigate(ROUTES.HOME)
            console.log('HERE');
          }
        },
        () => navigate(ROUTES.SIGN_IN)
      )
      return () => unsubscribe()
    })

    return (
      <AuthUserContext.Consumer>
        {authUser => (condition(authUser) ? <Component {...props} /> : null)}
      </AuthUserContext.Consumer>
    )
  }

  const withRouter = Component => props => {
    const navigate = useNavigate()
    const location = useLocation()

    return <Component {...props} navigate={navigate} location={location} />
  }

  return compose(withRouter, withFirebase)(WithAuthorization)
}

export default withAuthorization
