import React, { useEffect } from 'react'
import { withAuthorization, AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'
import { useSearchParams } from 'react-router-dom';

const ConnectedBase = props => {
  const user = props.firebase.auth.currentUser
  
  const [queryParameters] = useSearchParams();
  const spikeUserId = queryParameters.get('user_id');
  const provider = queryParameters.get('provider');
  
  //console.log(user)
  useEffect(() => {   
    if (user && spikeUserId && provider) {   
      const userData = {
        spikeUserId: spikeUserId,
        providers: [provider],
      };
      // Use the set method to create or overwrite the user's document
      const userRef = props.firebase.firedb.collection('users').doc(user?.uid);
      userRef.update(userData)
      .then(() => {
        console.log('User data added to Firestore');
      })
      .catch((error) => {
        console.error('Error adding user data to Firestore:', error);
      });
    } else {
      //Do something
    }
  }, [user]);
  
  return (
    <AuthUserContext.Consumer>
    {authUser => (
      <div className='accountBox'>
      <div className='accountContent'>
      <h1>Connected</h1>
      <p>{spikeUserId}</p>
      <p>{provider}</p>
      </div>
      </div>
      )}
      </AuthUserContext.Consumer>
      )
    }
    
    const ConnectedPage = withFirebase(ConnectedBase)
    
    const condition = authUser => !!authUser
    
    export default withAuthorization(condition)(ConnectedPage)
    