import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { AuthUserContext } from '../Session'

import { withFirebase } from '../Firebase'
import { AleapLogo } from '../Icons'

import SignOutButton from '../SignOut'
import * as ROUTES from '../../constants/routes'

const NavigationBase = props => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} props={props} />
      ) : (
        // <NavigationNonAuth />
        <></>
      )
    }
  </AuthUserContext.Consumer>
)

const NavigationAuth = ({ authUser, props }) => {
  const [subscription, setSubscription] = useState(null)
  let navigate = useNavigate()
  const user = authUser;
  // console.log('USER:', user);

  useEffect(() => {
    props.firebase.firedb
      .collection('customers')
      .doc(user?.uid)
      .collection('subscriptions')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(async subscription => {
          setSubscription({
            role: subscription.data().role,
            current_period_end: subscription.data().current_period_end.seconds,
            current_period_start: subscription.data().current_period_start
              .seconds
          })
        })
      })
  }, [subscription])

  // console.log('SUB:', subscription);

  const goToPayment = () => {
    console.log(subscription)

    if (subscription != null) {
      console.log("SUBSCRIPTION")
    } else {
      navigate(ROUTES.PAYMENT)
    }
  }

  return (
    <div className='siderBarMenu'>
      <AleapLogo className='logo' />
      <Link to={ROUTES.HOME}>Home</Link>
      <Link to={ROUTES.ACCOUNT}>Account</Link>
      <Link to={ROUTES.CONNECT}>Providers</Link>
      <SignOutButton />
    </div>
  )
}

const Navigation = withFirebase(NavigationBase)

export default Navigation
