import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Navigation from '../Navigation'
import LandingPage from '../Landing'
import SignUpPage from '../SignUp'
import SignInPage from '../SignIn'
import PasswordForgetPage from '../PasswordForget'
import PasswordChangePage from '../PasswordChange'
import EmailChangePage from '../EmailChange'

import HomePage from '../Home';
import PaymentPage from '../Payment'
import AccountPage from '../Account'
import AccountPaymentPage from '../AccountPayment'

import ConnectedPage from '../Connected'

import * as ROUTES from '../../constants/routes'
import { withAuthentication } from '../Session'

const App = () => (
  <BrowserRouter basename="/">
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap'
      }}
    >
      <Navigation />
      <Routes>
        {/* <hr /> */}
        <Route path={ROUTES.LANDING} element={<LandingPage />} />
        <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
        <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
        <Route path={ROUTES.PASSWORD_FORGET} element={<PasswordForgetPage />} />
        <Route path={ROUTES.PASSWORD_CHANGE} element={<PasswordChangePage />} />
        <Route path={ROUTES.EMAIL_CHANGE} element={<EmailChangePage />} />

        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.PAYMENT} element={<PaymentPage />} />
        <Route path={ROUTES.ACCOUNT} element={<AccountPage />} />
        <Route path={ROUTES.CONNECT} element={<AccountPaymentPage />} />
        <Route path={ROUTES.CONNECTED} element={<ConnectedPage />} />
      </Routes>
    </div>
  </BrowserRouter>
)

export default withAuthentication(App)
