import React from 'react'
import { compose } from 'recompose'

import { AuthUserContext, withAuthorization } from '../Session'
import PlansPage from './PlansPage'

const PaymentPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div className='centerContent'>
        <div className='sectionContent'>
          <h1 className='sectionTitle'>Connect provider</h1>
          <p>Connect a data provider</p>
          <PlansPage authUser={authUser} />
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
)

const condition = authUser => !!authUser

export default compose(withAuthorization(condition))(PaymentPage)
