import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'
import { withAuthorization } from '../Session'

const HomePage = () => (
  <div className='accountBox'>
    <div className='accountContent'>
      <h1>Home</h1>
      <p>Here is your current data</p>
      {/* <SelectPlanBase /> */}
    </div>
  </div>
)

const SelectPlanBase = props => {
  const [plan, setPlan] = useState('')
  // const [error, setError] = useState(null);
  let navigate = useNavigate()

  const pressYear = () => {
    console.log('YEAR')
    setPlan('595')
  }

  const pressMonth = () => {
    console.log('MONTH')
    setPlan('79')
    //setPlan(event.target.value)
  }

  const onSubmit = () => {
    navigate(ROUTES.PAYMENT, { state: { amount: plan } })
    console.log(plan)
    // console.log(event.drone);
  }

  const selectionBoxStyle = {
    width: '377px',
    height: '76px',
    background: '#58062A',
    border: '2px solid #E999BF',
    borderRadius: '8px',
    margin: 'auto',
    marginBottom: 16,
    display: 'block',
    fontSize: 18,
    color: '#ffffff'
  }

  return (
    <div>
      <button type='button' onClick={pressYear} style={selectionBoxStyle}>
        599 kr per year
      </button>

      <button type='button' onClick={pressMonth} style={selectionBoxStyle}>
        79 kr per månad
      </button>

      <button type='button' onClick={onSubmit} className='buttonStyle'>
        Fortsätt
      </button>
    </div>
  )
}

const condition = authUser => !!authUser

export default withAuthorization(condition)(HomePage)
